<template>
  <div class="container">
    <div
      class="d-flex flex-wrap align-items-center w-100 justify-content-center mb-5"
    >
      <div
        class="col-md-2 mb-3 mb-md-0 d-flex justify-content-center justify-content-md-start pl-md-0"
      >
        <z-back-button @click.native="back" />
      </div>
      <div class="col-12 col-md-8">
        <!--        <span dev @click="DEV_prefill">prefill</span>-->
        <h3 class="mb-0">
          Nastavenie účtu
        </h3>
      </div>
      <div class="col-md-2"></div>
    </div>
    <form class="pb-5 w-100 mw-100" @submit.prevent="updateUserInfo">
      <div class="section mb-5">
        <!--      <span dev @click="DEV_prefill">prefill</span>-->
        <h5 class="mb-3">Zákazník</h5>

        <b-card no-body class="p-1">
          <div class="row p-3">
            <div class="col-lg-6">
              <w-input v-model="$v.form.name" label="Meno" chevron />
            </div>

            <div class="col-lg-6">
              <w-input v-model="$v.form.surname" label="Priezvisko" chevron />
            </div>

            <div class="col-lg-4">
              <w-input
                v-model="$v.form.street"
                label="Ulica a číslo domu"
                chevron
              />
            </div>

            <div class="col-lg-2">
              <w-input v-model="$v.form.zip" type="text" label="PSČ" chevron />
            </div>

            <div class="col-lg-6">
              <w-input v-model="$v.form.city" label="Mesto" chevron />
            </div>

            <div class="col-lg-6">
              <w-input
                v-model="$v.form.email"
                name="email"
                label="Email"
                chevron
              />
            </div>

            <div class="col-lg-6">
              <w-input
                v-model="$v.form.phone"
                label="Telefónne číslo"
                chevron
              />
            </div>

            <div class="col-lg-6">
              <w-input
                v-model="$v.passwordForm.password"
                label="Nové heslo (min. 8 znakov)"
                type="password"
                chevron
              />
            </div>

            <div class="col-lg-6">
              <w-input
                v-model="$v.passwordForm.password_confirmation"
                label="Potvrďte nové heslo"
                type="password"
                chevron
              />
            </div>

            <div class="col-lg-12">
              <b-button
                pill
                variant="success"
                class="z-submit-btn float-right"
                @click="updateUserInfo"
                >Uložiť</b-button
              >
            </div>
          </div>
        </b-card>
      </div>

      <div class="section mb-5" v-if="form.students">
        <h5 class="mb-3">Študenti</h5>
        <b-card no-body>
          <b-tabs card>
            <b-tab v-for="(student, i) in form.students" :key="i">
              <template #title>
                <div class="d-flex align-items-center">
                  {{ student.name ? student.name : "Študent" }}
                </div>
              </template>
              <div class="row">
                <div class="col-lg-5">
                  <w-input
                    v-model="$v.form.students.$each[i].name"
                    label="Meno"
                    chevron
                  />
                </div>
                <div class="col-lg-5">
                  <w-input
                    v-model="$v.form.students.$each[i].surname"
                    label="Priezvisko"
                    chevron
                  />
                </div>
                <div class="col-lg-2">
                  <w-input
                    v-model="$v.form.students.$each[i].gender"
                    label="Pohlavie"
                    tag="b-form-select"
                    :selectOptions="genderDropdownOptions"
                    chevron
                  />
                </div>
                <div class="col-lg-6">
                  <w-input
                    v-model="$v.form.students.$each[i].birth_year"
                    label="Rok narodenia"
                    tag="b-form-select"
                    :selectOptions="years"
                    chevron
                  />
                </div>
                <div class="col-lg-4">
                  <w-input
                    v-model="$v.form.students.$each[i].school"
                    label="Škola, ktorú študent navštevuje"
                    chevron
                  />
                </div>
                <div class="col-lg-2">
                  <w-input
                    v-model="$v.form.students.$each[i].school_class"
                    label="Trieda"
                    chevron
                  />
                </div>
                <div class="col-lg-4">
                  <w-input
                    v-model="$v.form.students.$each[i].dietary_restrictions"
                    label="Obmedzenia stravy:"
                    tag="b-form-textarea"
                  />
                </div>
                <div class="col-lg-4">
                  <w-input
                    v-model="$v.form.students.$each[i].health_information"
                    label="Zdravotné informácie:"
                    tag="b-form-textarea"
                  />
                </div>
                <div class="col-lg-4">
                  <w-input
                    v-model="$v.form.students.$each[i].educational_needs"
                    label="Výchovno vzdelávacie potreby:"
                    tag="b-form-textarea"
                  />
                </div>
                <div class="col-lg-12">
                  <b-button
                    pill
                    variant="success"
                    class="z-submit-btn float-right"
                    @click="updateStudentInfo(i)"
                    >Uložiť</b-button
                  >
                </div>
              </div>
            </b-tab>
            <template #tabs-end>
              <b-nav-item
                v-if="form.students.length < 5"
                href="#"
                role="presentation"
                @click.prevent="addStudent"
                class="ml-3"
              >
                <b-icon icon="person-plus" style="margin-bottom: 2px"></b-icon>
                Pridať študenta
              </b-nav-item>
            </template>
          </b-tabs>
        </b-card>
      </div>
    </form>
  </div>
</template>

<script>
import {
  required,
  email,
  sameAs,
  minLength,
  numeric,
} from "vuelidate/lib/validators"
import wAxios from "@/plugins/w/axios"
export default {
  data() {
    return {
      passwordForm: {password: null, password_confirmation: null},
      form: {},
      genderDropdownOptions: [
        { value: "male", text: "chlapec" },
        { value: "female", text: "dievča" },
      ],
    }
  },
  async mounted() {
    this.form = await this.$store.state.wAuth.user
    this.$v.form.$touch()
  },

  validations() {
    return {
      passwordForm: {
        password_confirmation: {
          sameAsPassword: sameAs(function() {
            return this.passwordForm.password
          }),
        },
        password: { minLength: minLength(8) },
      },

      form: {
        email: { required, email },
        name: { required },
        surname: { required },
        phone: { required },
        street: { required },
        zip: { required, numeric },
        city: { required },

        students: {
          required,
          minLength: minLength(1),
          $each: {
            name: { required },
            surname: { required },
            gender: { required },
            birth_year: { required },
            school: { required },
            school_class: { required },
            dietary_restrictions: {},
            educational_needs: {},
            health_information: {},
          },
        },
      },
    }
  },

  methods: {
    updateUserInfo() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      let userData = this.form
      if (this.passwordForm.password) {
        userData = {
          ...userData,
          ...this.passwordForm
        }
      }

      try {
        wAxios
          .post_auth_data("v1/auth/info", userData)
          .then(() => {
            this.$wToast.success("Uložené")
            this.$store.dispatch("wAuth/userInfo")
          })
          .catch((error) => {
            this.$wToast.error(error)
          })
      } catch (error) {
        this.$wToast.error(error)
      }
    },
    async updateStudentInfo(studentId) {
      const id = this.form.students[studentId].id
      const studentData = this.form.students[studentId]

      if (id) {
        try {
          wAxios
            .patch_auth_data(`v1/auth/students/${id}`, studentData)
            .then(() => {
              this.$wToast.success("Uložené")
              this.$store.dispatch("wAuth/userInfo")
            })
        } catch (error) {
          this.$wToast.error(error)
        }
      } else {
        try {
          wAxios.post_auth_data(`v1/auth/students`, studentData).then(() => {
            this.$wToast.success("Uložené")
            this.$store.dispatch("wAuth/userInfo")
          })
        } catch (error) {
          this.$wToast.error(error)
        }
      }
    },
    addStudent() {
      this.form.students.push({
        name: null,
        surname: null,
        gender: null,
        birth_year: null,
        school: null,
        school_class: null,
      })
    },
    back() {
      this.$router.go(-1)
    },
  },

  computed: {
    years() {
      const todayYear = new Date().getFullYear()
      const years = []
      for (let year = new Date(0).getFullYear(); year <= todayYear - 7; year++)
        years.push(year)

      return years.reverse()
    },
  },
}
</script>
